<template>
  <div class="container">
    <h2>Basisregels</h2>
    <p>1. Er wordt gebruik gemaakt van 1 dobbelsteen.</p>
    <p>2. Het spel gaat met de klok mee.</p>
    <p>3. Je moet binnen 5 sec gooien want anders is je beurt voorbij.</p>
    <p>4. Vervolgens heb je dan 10 seconden om je pion te bewegen.</p>
    <p>5. De volgende speler is pas wanneer de beurt van de vorige speler is afgelopen. Dat houdt in dat hij heeft gegooid en zn pion heeft bewogen of als zn beurt voorbij is.</p>
    <p>6. Je moet een 6 gooien om een pion in het spel te krijgen. Vervolgens zal je dan ook met die pion gelijk moeten spelen.</p>
  </div>
</template>
<script>
export default {
  name: "spelregelsBegin"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: left;
  position: marker;
  padding: 50px;
  color: rgb(0, 0, 0);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}
</style>
