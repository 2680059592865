import {Pawn} from "@/models/pawn";

export class Player
{
  id;
  playerName;
  playerColor;
  pawnArray;
  pawnSaved;
  isWinner;
  throw;
  rolls;
  constructor(
    playerName,
    playerColor,
    playerId
  )
  {
    this.playerColor = playerColor;
    this.id = playerId;
    this.pawnArray = [];
    this._pawnSaved = 0;
    this.pawnOnBoard = 0;
    this.playerStartPoint = null;
    this.isWinner = false;
    this._playerName = playerName;
    this.throw = 0;
    this.rolls = 0;
    this.lastReturnedIndex = -1;
    //FIXME console log player created

    // console.log("Player Created")
    // console.log(this)
//FIXME name and pawnSaved is not being used "_"

  }

  givePlayerPawns(){
    for(let i=0;i<4;i++){
      let pawn = new Pawn(i+1,this.playerColor);
      pawn.setPawnPlayer(this);
      this.pawnArray.push(pawn);
    }
    // console.log(
    //   "\n"
    //   +this.playerName
    //   +" with the color: "
    //   +this.playerColor
    //   +" has the following pawns:\n "
    //   +JSON.stringify(this.pawnArray)+"\n")
  }
//fixme deadcode
  renderPawns(){
    for(const pawn of this.pawnArray){
      pawn.renderSVG();
    }
  }

  get playerColor() {
    return this.playerColor;
  }

  set playerColor(value) {
    this.playerColor = value;
  }


  get pawnArray() {
    return this.pawnArray;
  }

  set pawnArray(value) {
    this.pawnArray = value;
  }

  addOnBoard(){
    this.pawnOnBoard++;
  }

  addRoll(){
    this.rolls ++;
  }

  getRolls(){
    return this.rolls;
  }

  removeOnBoard(){
    this.pawnOnBoard = this.pawnOnBoard -1;
  }

  getOnBoard(){
    return this.pawnOnBoard;
  }

  savePawn(){
    this._pawnSaved++;
  }


  getNumberOfSavedPawns() {
    return this._pawnSaved;
  }

  set pawnSaved(value) {
    this._pawnSaved = value;
  }

  setPlayerStart(circle){
    this.playerStartPoint = circle;
  }

  getPlayerStart(){
    return this.playerStartPoint;
  }


  get name() {
    return this._playerName;
  }

  set name(value) {
    this._playerName = value;
  }

  getThrow(){
    return this.throw;
  }

  setThrow(value){
    this.throw = value;
  }

  getIsWinner() {
    return this.isWinner;
  }

  setIsWinner(trueOrFalse){
    this.isWinner = trueOrFalse;
  }

  //FIXME old pawn grabber
  grabPawnFromHomeBaseV1(){
    for(const pawn of this.pawnArray){
      if(pawn.isOnBoard() == false ){
        return pawn;
      }else {console.log("\ngrabPawnFromHomeBase from player.js: Seems like all the pawns are on board?!\n")}
    }
  }



   grabPawnFromHomeBase() {
  const pawnArray = this.pawnArray;
  const arrayLength = pawnArray.length;

  let startingIndex = (this.lastReturnedIndex + 1) % arrayLength;

  for (let i = startingIndex; i < arrayLength + startingIndex; i++) {
    const currentIndex = i % arrayLength;
    const pawn = pawnArray[currentIndex];

    if (pawn.isOnBoard() === false) {
      this.lastReturnedIndex = currentIndex;
      return pawn;
    } else {
      console.log("\ngrabPawnFromHomeBase from player.js: Seems like all the pawns are on board?!\n");
    }
  }
}

}
