export class Circle {
  index;
  numberID;
  codeName;
  circleID;
  x;
  y;
  xy;
  radius;
  color;
  colorStroke;
  occupied;
  constructor(
    index,
    codeName,
    x,
    y,
    radius,
    color,
    colorStroke,
  ) {
    this.index = index;
    this.numberID = this.index + 1;
    this.codeName = codeName;
    this.circleID = this.codeName + this.numberID;
    this.x = x;
    this.y = y;
    this.xy = "x"+this.x +"y"+this.y;
    this.radius = radius;
    this.color = color;
    this.colorStroke = colorStroke;
    this.occupied = false;
    this.pawn = null;
    this.createSVG();
  }

  createSVG() {
    const svgns = "http://www.w3.org/2000/svg";
    this.svgElement = document.createElementNS(svgns, "svg");
    this.svgElement.setAttribute("width", this.radius * 2);
    this.svgElement.setAttribute("height", this.radius * 2);

    const circle = document.createElementNS(svgns, "circle");
    circle.setAttribute("cx", this.radius);
    circle.setAttribute("cy", this.radius);
    circle.setAttribute("r", this.radius);
    circle.setAttribute("fill", this.color);
    circle.setAttribute("stroke", this.colorStroke);

    this.svgElement.appendChild(circle);
  }


  getX() {
    return this.x;
  }

  getY() {
    return this.y;
  }


  getIndex() {
    return this.index;
  }

  setOccupied(trueOrFalse){
    this.occupied = trueOrFalse;
  }

  isOccupied(){
    return this.occupied;
  }

  getPawn(){
    return this.pawn;
  }

  setPawn(pawn){
    this.pawn = pawn;
  }

  getCodeName(){
    return this.codeName;
  }

}
