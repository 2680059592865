<template>
  <div class="container">
    <h2>Verliezen van pionnen (2/2)</h2>
    <p>3. De tegenstander(s) kunnen op 3 manieren van het speelveld af gehaald worden. Door vooruit op een andere kleur te landen, zijwaarts of achteruit. Je speelt verder van waar het pion is geland. </p>
  </div>
</template>
<script>
export default {
  name: "spelregelsBegin3"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: left;
  position: marker;
  padding: 50px;
  color: rgb(0, 0, 0);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}
</style>
