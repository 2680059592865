<template>

  <div class="display">
    <p>{{info}}</p>
  </div>


</template>

<script>
export default {
  name:'Prompt',
  props:{
    info: String,
  },
  methods: {
  }

}

</script>

<style scoped>
.display{

  background-color: black;
  color: white;
  border: 5px solid #42b983;
  padding: 20px;
  width-max: 300px;
  margin-top: 20px;
}

</style>
