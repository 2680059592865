import soundPawnKill from '@/assets/sound_edit_killed.wav';

export class Pawn {

  constructor(number, color) {
    this.number = number;
    this.id = `${color}-${number}`;
    this._x = 0;
    this._y = 0;
    this.xDiff = 60;
    this.yDiff = 99.3;
    this.homeCircle = null;
    this._homeBasePosX = 0;
    this._homeBasePosY = 0;
    this._currentCircle = null;
    this.player = null;
    this._color = color;
    this.onBoard = false;
    this.clickable = false;
    this.startPoint = null;
    this.saved = false;
    this.block = false;
    this.winning = false;
  }

  get homeBasePosX() {
    return this._homeBasePosX;
  }

homeBasePosX(value) {
    this._homeBasePosX = value;
  }

  get homeBasePosY() {
    return this._homeBasePosY;
  }

  homeBasePosY(value) {
    this._homeBasePosY = value;
  }

  setCurrentCircle(circle){
    this._currentCircle = circle;
  }


  get currentCircle() {
    return this._currentCircle;
  }

  getCircleIndex(){
    return this._currentCircle.getIndex();
  }

  move(){
    this._x = this._currentCircle.getX();
    this._y = this._currentCircle.getY();

  }

  isBlocked(){
    return this.block
  }

  blockPawn(trueOrFalse){
    this.block = trueOrFalse;
  }

  place(circle){
    if(this.currentCircle !== null){
      this.currentCircle.setPawn(null);
      this.currentCircle.setOccupied(false);
      console.log("prev circle");
      console.log(this.currentCircle);
    }
    this.setCurrentCircle(circle);
    this.move();
    circle.setOccupied(true);
    circle.setPawn(this);

    for(const pawn of this.getPawnPlayer().pawnArray){
      if(pawn.isBlocked()){
        pawn.blockPawn(false);
        console.log(pawn.id, " is unblocked ");
      }
    }

    if(this.currentCircle.getCodeName() === "mainpath-"){
      console.log("current circle");
      console.log(this.currentCircle);
    }

  }

  blocker(roll, path){
    let current = this._currentCircle;
    let next = current.getIndex() + roll;
    let circle = path[next];

    const afterPurple = {
      "red": [0,1,2,3,4,5],
      "blue": [12,13,14,15,16,17],
      "green": [24,25,26,27,28,29],
      "yellow": [36,37,38,39,40,41],
    };

    const beforePurple = {
      "red": [41,42,43,44,45,46,47],
      "blue": [5,6,7,8,9,10,11],
      "green": [17,18,19,20,21,22,23],
      "yellow": [29,30,31,32,33,34,35],
    };

    let after = afterPurple[this._color];
    let before = beforePurple[this._color];

    let afterIncluded = false;
    let beforeIncluded = false;

    const mainPath = this._currentCircle.getCodeName();

    const check = mainPath === "mainpath-"
      && circle !== undefined
      && circle.isOccupied()
      && this._color === circle.getPawn().color;

    if(check){

      for(const num of after){
        if(num === circle.getIndex()){
          console.log("From pawn.blocker: num equals circle.getIndex() - afterIncluded set to true")
          afterIncluded = true;
        }
      }

      for(const num of before){
        if(num === current.getIndex()){
          console.log("From pawn.blocker: num equals circle.getIndex() - beforeIncluded set to true")
          beforeIncluded = true;
        }
      }
      console.log("checked");
      console.log("after");
      console.log(after);
      console.log(afterIncluded);
      console.log("before");
      console.log(before);
      console.log(beforeIncluded);
      if(!(afterIncluded && beforeIncluded)){
        console.log("checked2");
        console.log(this.id, " is blocked now ");
        this.blockPawn(true);
      }
    }
  }

  kill(pawn){
    const hostile = this._color !== pawn.color;
    if(hostile){
      pawn.place(pawn.getHomeCircle());
      pawn.setOnBoard(false);
      pawn.getPawnPlayer().removeOnBoard();

      const string1 = (this.getPawnPlayer().name + ' attacked ' + pawn.getPawnPlayer().name);
      const string2 = (this.id + " killed " +  pawn.id);
      console.log(string1);
      console.log(string2);

      const sound = new Audio(soundPawnKill);
      sound.preload = "auto";
      sound.play();
    }
  }


  setStartPoint(circle){
    this.startPoint = circle;
  }

  getStartPoint(){
    return this.startPoint;
  }

  isOnBoard(){
    return this.onBoard;
  }

  setOnBoard(trueOfFalse){
    this.onBoard = trueOfFalse;
  }

  isClickable(){
    return this.clickable;
  }

  setClickable(trueOrFalse){
    this.clickable = trueOrFalse;
  }


  getSaved() {
    return this.saved;
  }

  setSaved(trueOrFalse) {
    this.setOnBoard(false);
    this.saved = trueOrFalse;
    this.player.savePawn();

    console.log(
      this.id,
      " is saved pawn saved status is set to: ",
      this.getSaved());

    if(this.player.getNumberOfSavedPawns() === 4){
      this.player.setIsWinner(true);

      console.log(
        this.player.name,
        " has just been made a winner!\n Winner value: ",
        this.player.getIsWinner());
    }
    console.log(this.player);
  }


  get color() {
    return this._color;
  }

  set color(value) {
    this._color = value;
  }


  getWinning() {
    return this.winning;
  }

  setWinning(value) {
    this.winning = value;
  }

  getHomeCircle(){
    return this.homeCircle;
  }

  setHomeCircle(circle){
    this.homeCircle = circle;
  }

  setPawnPlayer(player){
    this.player = player;
  }

  getPawnPlayer(){
    return this.player;
  }

  renderSVG() {
    return `
     <g class="pawn" data-pawn-id="${this.id}">
      <symbol id="${this.id}" width="1200" height="1200">
        <ellipse
          cx="60"
          cy="80"
          rx="30"
          ry="40"
          stroke-width="7"
          stroke="black"
          opacity="1"/>
        <circle
          cx="60"
          cy="40"
          r="22"
          stroke-width="7"
          stroke="black"
          opacity="1"
          />
      </symbol>
      <use
        href="#${this.id}"
        x="${this._x - this.xDiff }"
        y="${this._y - this.yDiff}"
        fill="${this._color}"
      />
      </g>
    `;
  }

}
