<template>
  <div class="container">

    <h1>Developer: Kevin</h1>
    <br>
    <p>Ik ben Kevin Newland, ik ben 27 jaar oud werk als consultant bij Rabobank via XPRTZ.Cloud. </p>
    <p>Ik woon in Amsterdam</p>
    <p>Ik heb in het mens erger je niet project gewerkt aan de setup van de game logica en lobby in de backend samen met Jorden.</p>
    <p>Daarnaast heb ik me beziggehouden met het opzetten van websockets ,maken van de lobby UI en het over all mee denken en helpen van mijn klasgenoten/collega's waar nodig was.</p>
    <p>Tijdens ons project heb ik bezig gehouden met de backend en de CI/CD van dit project, waarbij ik me bezighield met cruciale taken zoals:</p>
    <p>Mijn speciliteti zit hem in backend development en UI/UX Design en ontwikkeling</p>

  </div>
</template>

<script>
export default {
  name: "Kevin"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: justify;
  position: marker;
  padding: 50px;
  color: rgb(255, 255, 255);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}


</style>
