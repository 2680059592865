<template>
  <div class="container">
    <h2>(Geen) Blokkades</h2>
    <p>1. Er mag maximaal 1 pion op 1 plek staan zodat er geen blokkade gevormd kan worden.</p>
  </div>
</template>
<script>
export default {
  name: "spelregelsBegin1"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: left;
  position: marker;
  padding: 50px;
  color: rgb(0, 0, 0);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}
</style>
