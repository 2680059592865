<template>
  <div class="container">
    <div class="row">
      <div class="form-floating mb3 game-lobby-1" id="username">
        <div class="card" style="width: 18rem;">
          <img src="" class="card-img-top" alt="Player image">
          <div class="card-body">
            <h5 class="card-title">Username</h5>
          </div>
        </div>
      </div>
      <div class="options-container" id="options">
        <button @click="options = !options">Start game</button>
        <button>Leave Lobby</button>
        <button>Kick player</button>
        <button>Pawn</button>
      </div>
      <div class="row">
        <div class="gameOptions" v-show="options">
          <button>1 vs pc </button>
          <button>1 vs 1 </button>
          <button>1 vs 2</button>
          <button>1 vs 3</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameLobby",
  props: ['gameLobby'],
  emits: ['addPlayer', 'startGame'],
  data() {
    return {
      options: false,
      players: [],
      gameStatus: ['waiting', 'started', 'ended'],
      images: null
    };
  },
  methods: {
    addPlayer(playerName) {
      this.players.push(playerName);
    },
    startGame() {
      this.gameStatus = 'started';
    }
  },
};
</script>

<style scoped>
/* //.game-lobby {
//  background-color: #f0f0f0;
//  padding: 20px;
//  border-radius: 8px;
//  text-align: center;
//} */
.row {
  margin-top: 5%;
  height: 50%;
  display: flex; /* Maak een flex-container */
  justify-content: space-between; /* Verdeel de ruimte tussen de elementen */
  align-items: flex-start; /* Verticaal uitlijnen aan de bovenkant */
}
.game-lobby-1 {
  background-color: rgb(0, 0, 0);
  right: 10%;
  width: 50%;
  padding: 20px;
  border-radius: 25px;
}
.options-container {
  display: flex; /* Maak een flex-container */
  align-items: center; /* Verticaal centreren van de knoppen */
}
.row {
  margin-top: 5%;
  height: 50%;
  display: flex; /* Maak een flex-container */
  justify-content: space-between; /* Verdeel de ruimte tussen de elementen */
  align-items: flex-start; /* Verticaal uitlijnen aan de bovenkant */
}
.game-lobby-1 {
  background-color: rgb(0, 0, 0);
  right: 10%;
  width: 50%;
  padding: 20px;
  border-radius: 25px;
}
.options-container {
  display: flex; /* Maak een flex-container */
  align-items: center; /* Verticaal centreren van de knoppen */
}

.gameOptions{
  margin-right: 80%;
}

button {
  border-radius: 25px;
  border: 1px solid #000000;
  background-color: #000000;
  width: 10%;
  height: 10%;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  padding: 20px;
  margin-right: 2%;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.1s ease-in;

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: none;
  }
}
</style>
