<template>
  <Navbar/>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

html body {
  background-color: rgba(251, 92, 4);
  margin-top: 5%;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: white;
}

nav a {
  color:#ff8200;
}
</style>
<script>
import Navbar from "@/components/main/Navbar";
import SocketioService from "./services/socketio-service"
export default {
  name:'App',
  components: {
    Navbar,
  },
  created(){

  },
/*  beforeUnmount() {

  }*/
}
</script>
