<template>
  <div class="header">
    <router-link :to="showButtonA ? '/login' : '/game'">
      <button class="homepage playbutton" @click="toggleButton">
        {{ showButtonA ? 'Play Game' : 'Sign in' }}
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data(){
    return {
      showButtonA: sessionStorage.getItem("loggedIn"),
    }
  },
  methods: {
    toggleButton() {
      this.showButtonA = !this.showButtonA;
    },
  },
}
</script>

<style scoped>
.header{
  background-image: url('../../assets/MensErgerJeNietLogo2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

button {
  background: rgba(7, 5, 5, 0.95);
  border: none;
  color: rgb(255, 255, 255);
  font-size: 20px;
  border-radius: 12px;
  padding: 20px 35px 20px;
  margin-top: 50px;
  margin-bottom: 100px;
  display: inline-block;
}

.homepage.playbutton{
  margin-top: 1000px;
}


</style>
