<template>
  <nav class="navbar navbar-expand-lg mat-elevation-z2 navbar fixed-top">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/home"><i class="bi bi-house-door-fill"></i> Mens Erger Je Niet</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item dropdown" v-show="showTabs">
            <router-link
              aria-current="page"
              aria-expanded="false"
              class="nav-link active dropdown-toggle"
              role="button"
              :to="{name: 'lobbyOverview'}"
            >Game Lobby</router-link>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" :to="{name: 'GameLobby'}">New game</router-link></li>
              <li><router-link class="dropdown-item" to="/game">Load Game</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-show="showTabs">
            <button aria-current="page" aria-expanded="false" class="nav-link active dropdown-toggle" role="button">Game Data</button>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/scoreboard">Scoreboard</router-link></li>
              <li><router-link class="dropdown-item" to="/history">History</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-show="showTabs">
            <router-link aria-current="page"
                         aria-expanded="false"
                         class="nav-link active dropdown-toggle"
                         role="button"
                         to="/intro">About</router-link>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/lloyd">Lloyd</router-link></li>
              <li><router-link class="dropdown-item" to="/kevin">Kevin</router-link></li>
              <li><router-link class="dropdown-item" to="/jorden">Jorden</router-link></li>
              <li><router-link class="dropdown-item" to="/max">Max</router-link></li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav login-section ">
          <li class="nav-item">
            <button class="nav-link active" @click="() => TogglePopup('buttonTrigger')" v-show="showTabs"> <i class="bi bi-question-circle-fill"></i> Help </button>
            <Popup
              v-if="popupTriggers.buttonTrigger"
              :TogglePopup="() => TogglePopup('buttonTrigger')">
              <h2>Mens Erger Je Niet Spelregels</h2>
            </Popup>
          </li>
          <li class="nav-item" v-if="!isLoggedIn">
            <router-link aria-current="page" class="nav-link active" to="/login">
              <i class="bi bi-people-fill people"></i>Login / Register
            </router-link>
          </li>
          <li class="nav-item dropdown" v-else>
            <button aria-current="page" aria-expanded="false" class="nav-link active dropdown-toggle" role="button" id="UserLoggedIn">
              Welkom, {{ loggedInUser.firstName }} {{ loggedInUser.lastName }}
            </button>
            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/reset">Reset Password</router-link></li>
              <li><button class="dropdown-item" @click="signOut">Sign out</button></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {ref} from 'vue';
import Popup from "@/components/main/Popup.vue";

let baseUrl =  "https://mejn.maxturk.nl";

export default {
  beforeCreate() {
    if (window.performance && performance.navigation && performance.navigation.type === 1) {
      this.$router.push('/home');
    }
  },
  components: {Popup},
  data() {
    return {
      loggedInUser: {
        firstName: '',
        lastName: '',
      },
      isLoggedIn: sessionStorage.getItem("loggedIn"),
      showTabs: sessionStorage.getItem("loggedIn"),
    }
  },
  methods: {
    signOut() {
      sessionStorage.clear();
      location.reload();
    },
    async fetchLoggedInUser() {
      const token = sessionStorage.getItem("token");
      if (token) {
        try {
          const response = await fetch(baseUrl + "/api/v1/auth/getUserName", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const user = await response.json();
            this.loggedInUser.firstName = user.firstName;
            this.loggedInUser.lastName = user.lastName;
          } else {
            console.error("Error fetching user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    },
  },
  mounted() {
    this.fetchLoggedInUser();
  },
  setup() {
    const popupTriggers = ref({
      buttonTrigger: false,
    });
    const TogglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger]
    }
    return {
      Popup,
      popupTriggers,
      TogglePopup
    }
  },
  name: 'Navbar'
}
</script>


<style scoped>

.login-section {
  margin-left: auto;
  margin-right: unset !important;
}

button:hover{
  background-color: #ff8200;
  border-radius: 10%;
}

button:visited{
  background-color: #ff8200;
  border-radius: 10%;
}

a:hover {
  background-color: #ff8200;
  border-radius: 10%;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

a:visited {
  background-color: #ff8200;
  border-radius: 10%;
}

.navbar-nav {
  margin-right: auto;
}

</style>
