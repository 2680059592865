<template>
  <article>
    <div class ="container">
      <form class="sign-in" enctype='multipart/form-data' @submit.prevent="resetPassword">
        <h2>Reset Password</h2>

        <div class="left">
          <span>Email</span>
        </div>
        <div v-if="emailError" class="error-message">{{ emailError }}</div>
        <input type="email" placeholder="Email" v-model="registerForm.email" required/>


        <div class="left">
          <span>New Password</span>
        </div>
        <div v-if="passwordError" class="error-message">{{ passwordError }}</div>
        <input type="password" placeholder="Password" v-model="registerForm.password" @input="validatePassword" @focusout="validatePassword" required/>
        <div class="left">
          <span>Confirm New Password</span>
        </div>
        <input type="password" placeholder="Confirm password" v-model="registerForm.passwordConfirm" required/>
        <button @click="forgotPassword">Submit!</button>
        <div class="suggests" v-show="!isLoggedIn">
          <div class="forgot">
            <router-link aria-current="page" class="nav-link active" to="/login"><i class="bi bi-people-fill people"></i>Login / Register</router-link>
          </div>
        </div>
      </form>
    </div>
  </article>
</template>

<script>

let baseUrl = "https://api.mejn.maxturk.nl";

export default {
  data ()  {
    return {
      isLoggedIn: sessionStorage.getItem("loggedIn"),
      passwordError: '',
      showDiv: false,
      email:'',
      emailError: '',
      registerForm:{
        email: "",
        password: "",
        passwordConfirm: "",
      },
      name: 'reset',
      signUp: false
    }
  },
  methods: {
    async resetPassword() {
      const emailExists = await this.checkEmailExists(this.registerForm.email);
      if (!emailExists && !this.validatePassword()) {
        console.error("Email doesn't exists");
        this.emailError = "Ingevoerde Email is niet bekend bij ons.";
        return;
      }
      try {
        const response = await fetch(baseUrl + "/api/v1/auth/reset-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.registerForm),
        });
        if (response.ok) {
          this.registerForm.email = "";
          this.registerForm.password = "";
          this.registerForm.passwordConfirm = "";
          alert("Wachtwoord is veranderd, bewaar het goed.")
        } else {
          console.error("Error resetting password");
        }
      } catch (error) {
        console.error("Error:", error);

      }
    },
    validatePassword() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\W)(?=.{6,15}).*$/;
      if (!passwordRegex.test(this.registerForm.password)) {
        this.passwordError = 'Het wachtwoord moet minimaal 6 tot en met 15 tekens bevatten, 1 hoofdletter en 1 speciaal teken.';
        return false;
      } else if (!this.registerForm.password || this.registerForm.password === "") {
        this.passwordError = 'Het wachtwoord mag niet leeg zijn.';
        return false;
      } else if (this.registerForm.password !== this.registerForm.passwordConfirm) {
        this.passwordError = 'De wachtwoorden komen niet overeen.';
        return false;
      } else {
        this.passwordError = "";
        return true;
      }
    },
    async checkEmailExists(email) {
      try {
        const response = await fetch(baseUrl + "/api/v1/auth/check-email", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email }),
        });
        if (response.ok) {
          const data = await response.json();
          return data.exists;
        } else {
          console.error("Error checking email existence");
          return false;
        }
      } catch (error) {
        console.error("Error checking email existence:", error);
        return false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-message {
  color:red;
  margin: 1%;
}

.container {
  position: relative;
  width: 50%;
  height: 700px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0,0,0,.2),
  0 10px 10px  rgba(0,0,0,.2);
  background: linear-gradient(to bottom, #efefef, #ccc);
}

h2 {
  margin: 0;
}

button {
  border-radius: 20px;
  border: 1px solid #000000;
  background-color: #000000;
  margin-top: 10px;
  color: #f84e07;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform .1s ease-in;

  &:active {
    transform: scale(.9);
  }

  &:focus {
    outline: none;
  }
}

.sign-in {
  width: 50%;
  left: 25%;
  top: 5%;
  z-index: 1;
  opacity: 1;
}

button.invert {
  background-color: transparent;
  border-color: #fff;
}

form {
  position: absolute;
  top: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 90px 60px;
  width: calc(50% - 100px);
  height: calc(100% - 180px);
  text-align: center;
  background: transparent;
  transition: all .5s ease-in-out;

  div {
    font-size: 1rem;
  }

  input {
    background-color: #eee;
    border: none;
    padding: 20px 40px;
    margin: 6px 0;
    width: 120%;
    border-radius: 15px;
    border-bottom: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .4),
    0 -1px 1px #fff,
    0 1px 0 #fff;
    overflow: hidden;

    &:focus {
      outline: none;
      background-color: #fff;
    }
  }

  .suggests{
    width: 120%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;

    .forgot{
      color: #f84e07;
      cursor: pointer;
    }
  }
}
</style>
