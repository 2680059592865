<template>
<div>
  <h1>Mens erger je niet</h1>
  <div class="container">
    <div class="board-position">
      <svg
        id="svgmainboard"
        viewBox="0 0 1200 1200" class="gif "  >
        <circle v-for="circle in circles" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="6"
                stroke="black"
                :fill="circle.color"
                opacity="100"
        />
        <circle v-for="circle in redPath" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                :fill="circle.color"
                opacity="100"
        />
        <circle v-for="circle in bluePath" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                :fill="circle.color"
                opacity="100"
        />
        <circle v-for="circle in yellowPath" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                :fill="circle.color"
                opacity="100"
        />
        <circle v-for="circle in greenPath" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                :fill="circle.color"
                opacity="100"
        />
        <circle v-for="circle in redBase" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                :fill="circle.color"
                opacity="100"
        />
        <circle v-for="circle in blueBase" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                fill="blue"
                opacity="100"
        />
        <circle v-for="circle in yellowBase" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                fill="yellow"
                opacity="100"
        />
        <circle v-for="circle in greenBase" :key="circle.index"
                :cx="circle.x"
                :cy="circle.y"
                r="33"
                stroke-width="7"
                stroke="black"
                fill="green"
                opacity="100"
        />
        <g ref="pawnsContainer" @click="pawnClickManager">
          <g v-html="allPawnsSVG"></g>
        </g>
      </svg>
    </div>
    <div class="side-position">
      <div class="prompter">
        <Prompt :info="promptInfo"/>
      </div>
      <div class="button-container">
        <button class="button_dice" @click="rollDice(this.dice.rollTime())" :disabled="!dicePermission"></button>
        <div v-if="devOptions">
          <button class="button_dev" @click="hidingDiv">Show dev options</button>
          <div v-if="isDivVisible">
            <div class="player-info">
              {{ this.getCurrentPlayer().name }} {{ this.getCurrentPlayer().playerColor }} <br>
              R: {{ this.players[0].name }} B: {{ this.players[1].name }} Y: {{ this.players[2].name }} G:
              {{ this.players[3].name }}
            </div>
            <div class="dev-buttons-rows">
              <button class="button_dev" @click="rollDice(1)" :disabled="!dicePermission">Dice One</button>
              <button class="button_dev" @click="rollDice(2)" :disabled="!dicePermission">Dice Two</button>
              <button class="button_dev" @click="rollDice(3)" :disabled="!dicePermission">Dice Three</button>
              <button class="button_dev" @click="rollDice(4)" :disabled="!dicePermission">Dice Four</button>
              <button class="button_dev" @click="rollDice(5)" :disabled="!dicePermission">Dice Five</button>
              <button class="button_dev" @click="rollDice(6)" :disabled="!dicePermission">Dice Six</button>
              <button class="button_dev" @click="softLockSkip()">Skip Turn</button>
              <button class="button_dev" @click="blockedBeforeWinPath()">Path Block</button>
              <button class="button_dev" @click="perfectFit()">Perfect Fit</button>
              <button class="button_dev" @click="normalBlock()">Normal Block</button>
              <button class="button_dev" @click="pathBlocker()">Path Wall</button>
              <button class="button_dev" @click="closeToWin()">Winning</button>
              <button class="button_dev" @click="killIt()">Kill</button>
            </div>
            <div style="width: 600px; margin-left: -100px; margin-top: 50px; background: #222222;  color: #dddddd">
              <p>
                R: {{ this.players[0].name }} B: {{ this.players[1].name }} Y: {{ this.players[2].name }} G:
                {{ this.players[3].name }}<br>
                {{ this.getCurrentPlayer().name }}
                Color :{{ this.getCurrentPlayer().playerColor }}
                On Board: {{ this.getCurrentPlayer().getOnBoard() }}
                Pawn Saved: {{ this.getCurrentPlayer().getNumberOfSavedPawns() }}<br>
                {{ this.getCurrentPlayer().pawnArray[0].id }}
                #click: {{ this.getCurrentPlayer().pawnArray[0].isClickable() }}
                #onB: {{ this.getCurrentPlayer().pawnArray[0].isOnBoard() }}
                #Block: {{ this.getCurrentPlayer().pawnArray[0].isBlocked() }}
                #winning: {{ this.getCurrentPlayer().pawnArray[0].getWinning() }}
                #saved: {{ this.getCurrentPlayer().pawnArray[0].getSaved() }}<br>
                {{ this.getCurrentPlayer().pawnArray[1].id }}
                #click: {{ this.getCurrentPlayer().pawnArray[1].isClickable() }}
                #onB: {{ this.getCurrentPlayer().pawnArray[1].isOnBoard() }}
                #Block: {{ this.getCurrentPlayer().pawnArray[1].isBlocked() }}
                #winning: {{ this.getCurrentPlayer().pawnArray[1].getWinning() }}
                #saved: {{ this.getCurrentPlayer().pawnArray[1].getSaved() }}<br>
                {{ this.getCurrentPlayer().pawnArray[2].id }}
                #click: {{ this.getCurrentPlayer().pawnArray[2].isClickable() }}
                #onB: {{ this.getCurrentPlayer().pawnArray[2].isOnBoard() }}
                #Block: {{ this.getCurrentPlayer().pawnArray[2].isBlocked() }}
                #winning: {{ this.getCurrentPlayer().pawnArray[2].getWinning() }}
                #saved: {{ this.getCurrentPlayer().pawnArray[2].getSaved() }}<br>
                {{ this.getCurrentPlayer().pawnArray[3].id }}
                #click: {{ this.getCurrentPlayer().pawnArray[3].isClickable() }}
                #onB: {{ this.getCurrentPlayer().pawnArray[3].isOnBoard() }}
                #Block: {{ this.getCurrentPlayer().pawnArray[3].isBlocked() }}
                #winning: {{ this.getCurrentPlayer().pawnArray[3].getWinning() }}
                #saved: {{ this.getCurrentPlayer().pawnArray[3].getSaved() }}<br>
              </p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
</template>
<script>

import {Dice} from "@/models/dice";
import {BoardSVG} from "@/models/boardSVG";
import {Logic} from "@/models/logic";
import Prompt from '@/components/Prompt.vue';
import soundDenied  from '@/assets/sound_edit_denied.wav';

export default {
  name: "game",
  props: {},
  components: {
    Prompt
  },
  data() {
    return {
      players: [],
      winners: [],
      playerNamesTest: ["Jorden", "Lloyd", "Kevin", "Max"],
      dice: new Dice(),
      circles: [],
      redPath: [],
      bluePath: [],
      greenPath: [],
      yellowPath: [],
      redBase: [],
      blueBase: [],
      greenBase: [],
      yellowBase: [],
      currentPlayer: 0,
      promptInfo: '',
      moveByDice: 0,
      lastDiceThrow: null,
      skip: false,
      dicePermission: true,
      isDivVisible: false,
      devOptions: true,
    }
  },
  created() {
    const board = new BoardSVG()
    const logic = new Logic();

    board.boardGameSvg(
      "svgmainboard",
      this.circles,
      this.redPath,
      this.redBase,
      this.bluePath,
      this.blueBase,
      this.greenPath,
      this.greenBase,
      this.yellowPath,
      this.yellowBase
    )

    const bases = {
      "red": this.redBase,
      "blue": this.blueBase,
      "yellow": this.yellowBase,
      "green": this.greenBase
    };

    logic.createPlayers(this.players, 4, this.playerNamesTest, false);
    logic.execute(this.players, 'givePlayerPawns');
    logic.placePawnOnHome(bases, this.players, this.circles);
    console.log(this.players);
    this.updatePrompt();

  },
  methods: {
    getCurrentPlayer() {
      return this.players[this.currentPlayer];
    },
    findPawnById(pawnId) {
      for (const player of this.players) {
        for (const pawn of player.pawnArray) {
          if (pawn.id === pawnId) {
            return pawn;
          }
        }
      }
      return null;
    },
    onClickPawn(click) {
      const clicked = click.target;
      const e = clicked.closest('.pawn');
      if (e) {
        const pawnId = e.dataset.pawnId;
        const clickedPawn = this.findPawnById(pawnId);
        this.selectedPawn = clickedPawn;
        if(!this.selectedPawn.isClickable() || this.selectedPawn.isBlocked()){
          const sound = new Audio(soundDenied);
          sound.preload = "auto";
          sound.play();
        }
        console.log(this.selectedPawn);
      }
    },
    movement() {
      const limitRed = 47;
      const limitBlue = 11;
      const limitGreen = 23;
      const limitYellow = 35;

      const colorLimits = {
        red: limitRed,
        blue: limitBlue,
        yellow: limitYellow,
        green: limitGreen,
      };

      //note: first if state block
      //note: checking if pawn is ready to move to its winning path, if so winning path placement

      if (this.selectedPawn.isClickable() && this.selectedPawn.getWinning()) {
        const currentPlayer = this.players[this.currentPlayer];
        const current = this.selectedPawn.getCircleIndex();
        let index = current + currentPlayer.getThrow();
        const colorPath = this.selectedPawn.color + 'Path';
        const path = this[colorPath];

        if (this.selectedPawn.currentCircle.getCodeName() !== "mainpath-" && index < path.length) {
          if (index >= 4 && !this.selectedPawn.getSaved()) {
            index = 4;
            let next = path[index];
            while (next.isOccupied()) {
              next = path[--index];
            }
            this.selectedPawn.place(next);
            this.selectedPawn.setSaved(true);
          } else if (index < 4 && !this.selectedPawn.getSaved()) {
            let next = path[index];
            while (next.isOccupied()) {
              next = path[--index];
            }
            this.selectedPawn.place(next);
          }
          const nextCircleIndex = this.selectedPawn.getCircleIndex() + 1;
          if (nextCircleIndex < path.length && path[nextCircleIndex].getPawn()?.getSaved()) {
            this.selectedPawn.setSaved(true);
          }

          for (const pawn of currentPlayer.pawnArray) {
            pawn.setClickable(false);
          }
          this.dicePermission = true;
          if (currentPlayer.getThrow() !== 6 && !this.skip) {
            this.playerRotation();
          }
        }
      }

      //note: end first if state block && start second if state block
      // note: normal pawn movement, checks if the pawn has reached the board 47 limit
      //  note: for the red pawn this means entering the its winning path.
      //  note: All other colors will move on to the first circle and beyond

      if (this.selectedPawn.isClickable() && !this.selectedPawn.getWinning()) {
        const currentPlayer = this.players[this.currentPlayer];
        const current = this.selectedPawn.getCircleIndex();
        let index = current + currentPlayer.getThrow();

        const color = this.selectedPawn.color;
        const mainPath = this.selectedPawn.currentCircle.getCodeName() === "mainpath-";


        if (color !== 'red' && index > limitRed) {
          const overLimit = (index - limitRed) - 1;
          index = overLimit;
        }

        if (mainPath && current <= colorLimits[color] && index > colorLimits[color]) {
          this.selectedPawn.setWinning(true);
        }

        if (!this.selectedPawn.getWinning()) {
          let next = this.circles[index];
          if (next.isOccupied() && next.getPawn() !== null) {
            this.selectedPawn.kill(next.getPawn());
          }

          this.selectedPawn.place(next);

          for (const pawn of currentPlayer.pawnArray) {
            pawn.setClickable(false);
          }

          this.dicePermission = true;
          if (currentPlayer.getThrow() !== 6 && !this.skip) {
            this.playerRotation();
          }
        }
      }

      //note end second if state block

      //note start third if state block

      if (this.selectedPawn.isClickable() && this.selectedPawn.getWinning()) {
        let currentPlayer = this.players[this.currentPlayer];
        let current = this.selectedPawn.getCircleIndex();
        let index = current + currentPlayer.getThrow();

        const color = this.selectedPawn.color;
        const mainPath = this.selectedPawn.currentCircle.getCodeName() === "mainpath-";

        if (mainPath && current <= colorLimits[color] && index > colorLimits[color]) {
          index = index - colorLimits[color];

          let sub = 1;
          if (index - sub >= 5) {
            index = 5;
          }

          let path = this[color + 'Path'];
          let next = path[index - sub];

          while (next.isOccupied() && next.getIndex() !== 0) {
            sub++;
            next = path[index - sub];
          }

          if (next.isOccupied() && next.getIndex() === 0) {
            next = this.circles[colorLimits[color]];
          }

          this.selectedPawn.place(next);

          if (this.selectedPawn.currentCircle.getCodeName() !== "mainpath-") {
            let num = this.selectedPawn.getCircleIndex() + 1;
            let circleExist = path[num] !== undefined && path[num] !== null;
            let isOccupied = path[num]?.getPawn() ?? false;
            if (circleExist && isOccupied) {
              if (path[num].getPawn().getSaved() || next.getIndex() === 4) {
                this.selectedPawn.setSaved(true);
              }
            }
          }

          if (next.getIndex() === 4) {
            this.selectedPawn.setSaved(true);
          }

          for (const pawn of currentPlayer.pawnArray) {
            pawn.setClickable(false);
          }

          this.dicePermission = true;
          if (currentPlayer.getThrow() !== 6 && !this.skip) {
            this.playerRotation();
          }
        }
      }
      for (const player of this.players) {
        if (player.getIsWinner()) {
          window.alert(`Player ${player.name} is the winner!`);
        }
      }
    },
    pawnClickManager(pawn) {
      this.onClickPawn(pawn);
      this.movement();
    },
    canSpawn() {
      const currentPlayer = this.getCurrentPlayer();
      const onBoardCount = currentPlayer.getOnBoard();
      const isEmpty = onBoardCount === 0;
      const withinPawnCount = onBoardCount > 0 && onBoardCount < 4;

      let hostile = false;

      if (currentPlayer.getPlayerStart().isOccupied()) {
        hostile = currentPlayer.getPlayerStart().getPawn().color !== currentPlayer.color;
      }

      const startIsClear = !currentPlayer.getPlayerStart().isOccupied() || (currentPlayer.getPlayerStart().isOccupied() && hostile);
      return (isEmpty || withinPawnCount) && startIsClear;
    },
    spawnPawn() {
      let spawn = this.getCurrentPlayer().grabPawnFromHomeBase();

      if (this.getCurrentPlayer().getPlayerStart().isOccupied()
        && this.getCurrentPlayer().getPlayerStart().getPawn().color
        !== this.getCurrentPlayer().color) {
        spawn.kill(this.getCurrentPlayer().getPlayerStart().getPawn());
      }

      spawn.place(this.getCurrentPlayer().getPlayerStart());
      spawn.setOnBoard(true);
      this.getCurrentPlayer().addOnBoard();
    },
    rollDice(roll) {

      const player = this.getCurrentPlayer();
      player.setThrow(roll);
      player.addRoll();
      let spawn = this.canSpawn();
      console.log(
        this.getCurrentPlayer().name, "roll", roll, "num", this.currentPlayer, "board", this.getCurrentPlayer().getOnBoard());
      this.moveByDice = roll;
      this.lastDiceThrow = roll;
      this.updatePrompt();

      let spawned = false;
      this.skip = false;

      const startPointClear = !this.getCurrentPlayer().getPlayerStart().isOccupied();


      if (
        !startPointClear
        && this.getCurrentPlayer().getPlayerStart().getPawn().color
        === this.getCurrentPlayer().playerColor
      ) {
        let pawnOnStart = this.getCurrentPlayer().getPlayerStart().getPawn();

        this.skip = true;

        pawnOnStart.blocker(roll, this.circles);

        const blocked = pawnOnStart.isBlocked();

        if (blocked) {
          pawnOnStart.place(pawnOnStart.getHomeCircle());
          pawnOnStart.setOnBoard(false);
          pawnOnStart.getPawnPlayer().removeOnBoard();
        }

        if (!blocked) {
          let nextCircle = (roll + pawnOnStart.getCircleIndex());
          if (this.circles[nextCircle].isOccupied()) {
            if (this.circles[nextCircle].getPawn().color !== pawnOnStart.color) {
              pawnOnStart.kill(this.circles[nextCircle].getPawn());
            }
          }
          pawnOnStart.place(this.circles[nextCircle]);

        }
      }


      if (roll === 6) {
        if (spawn) {
          this.spawnPawn();
          spawned = true;
        }
      }

      let hostileAtStart = false;

      if (player.getPlayerStart().getPawn() !== null) {
        hostileAtStart = player.getPlayerStart().getPawn().color !== player.color;
      }

      if (this.getCurrentPlayer().getOnBoard() > 0 && !spawned && (startPointClear || hostileAtStart)) {
        this.dicePermission = false;
        for (const pawn of player.pawnArray) {
          pawn.blocker(roll, this.circles);
          if (pawn.isOnBoard() && pawn.getSaved() === false && !pawn.isBlocked()) {
            console.log("clicking")
            pawn.setClickable(true);
          }
        }
      }

      if (this.getCurrentPlayer().getOnBoard() === 0 && roll < 6) {
        this.playerRotation();
      }

    },
    updatePrompt() {
      let lastPlayer = this.players[(this.currentPlayer === 0 ? this.players.length - 1 : this.currentPlayer - 1)];
      let lastPlayerColor = lastPlayer.playerColor;
      let lastPlayerName = lastPlayer.name;
      let lastDiceThrow;

      if (this.lastDiceThrow != null && this.lastDiceThrow != 6) {
        lastDiceThrow = `${lastPlayerName} (${lastPlayerColor}) rolled: ${this.lastDiceThrow}`;
      } else {
        lastDiceThrow = `Let the game begin!!!`;
        lastPlayerName = '';
        lastPlayerColor = '';
      }

      const currentPlayer = this.getCurrentPlayer();
      const currentPlayerColor = currentPlayer.playerColor;
      const currentPlayerName = currentPlayer.name;

      const currentPlayerColorDeep = JSON.parse(JSON.stringify(currentPlayer.playerColor));
      const currentPlayerNameDeep = JSON.parse(JSON.stringify(currentPlayer.name));


      if (this.lastDiceThrow === 6) {
        lastDiceThrow = `${currentPlayerNameDeep} (${currentPlayerColorDeep}) rolled: 6`;
      }

      this.promptInfo = `${lastDiceThrow}
      It's ${currentPlayerName}'s (${currentPlayerColor}) turn
      ${currentPlayer.getOnBoard()} pawns on board and ${currentPlayer.getNumberOfSavedPawns()} pawns saved`;
    },
    playerRotation() {
      this.currentPlayer = (this.currentPlayer + 1) % this.players.length;

      this.updatePrompt();
    },
    hidingDiv() {
      this.isDivVisible = !this.isDivVisible;
    },
    softLockSkip() {

      this.currentPlayer = (this.currentPlayer + 1) % this.players.length;
      this.dicePermission = true;
      this.updatePrompt();
    },
    blockedBeforeWinPath() {

      let red = this.players[0];
      let blue = this.players[1];
      let yellow = this.players[2];
      let green = this.players[3];

      red.pawnArray[0].place(this.circles[1]);
      red.pawnArray[1].place(this.circles[45]);


      blue.pawnArray[0].place(this.circles[10]);
      blue.pawnArray[1].place(this.circles[13]);

      green.pawnArray[0].place(this.circles[21]);
      green.pawnArray[1].place(this.circles[25]);

      yellow.pawnArray[0].place(this.circles[33]);
      yellow.pawnArray[1].place(this.circles[37]);

      red.pawnArray[0].setOnBoard(true);
      red.pawnArray[1].setOnBoard(true);
      red.addOnBoard();
      red.addOnBoard();

      blue.pawnArray[0].setOnBoard(true);
      blue.pawnArray[1].setOnBoard(true);
      blue.addOnBoard();
      blue.addOnBoard();

      green.pawnArray[0].setOnBoard(true);
      green.pawnArray[1].setOnBoard(true);
      green.addOnBoard();
      green.addOnBoard();

      yellow.pawnArray[0].setOnBoard(true);
      yellow.pawnArray[1].setOnBoard(true);
      yellow.addOnBoard();
      yellow.addOnBoard();


    },
    killIt() {
      let red = this.players[0];
      red.pawnArray[0].place(this.circles[15]);
      red.pawnArray[0].setOnBoard(true);
      red.addOnBoard();

      let blue = this.players[1];
      blue.pawnArray[0].place(this.circles[16]);
      blue.pawnArray[0].setOnBoard(true);
      blue.addOnBoard();

    },
    perfectFit() {
      let red = this.players[0];
      red.pawnArray[0].place(this.circles[47]);
      red.pawnArray[0].setOnBoard(true);
      red.addOnBoard();

      let blue = this.players[1];
      blue.pawnArray[0].place(this.circles[11]);
      blue.pawnArray[0].setOnBoard(true);
      blue.addOnBoard();

      let green = this.players[3];
      green.pawnArray[0].place(this.circles[23]);
      green.pawnArray[0].setOnBoard(true);
      green.addOnBoard();

      let yellow = this.players[2];
      yellow.pawnArray[0].place(this.circles[35]);
      yellow.pawnArray[0].setOnBoard(true);
      yellow.addOnBoard();

    },
    normalBlock() {
      let red = this.players[0];
      red.pawnArray[0].place(this.circles[1]);
      red.pawnArray[1].place(this.circles[6]);
      red.pawnArray[0].setOnBoard(true);
      red.pawnArray[1].setOnBoard(true);
      red.addOnBoard();
      red.addOnBoard();
    },
    closeToWin() {
      let red = this.players[0];
      red.pawnArray[0].place(this.circles[46]);
      red.pawnArray[1].place(this.circles[45]);
      red.pawnArray[2].place(this.circles[44]);
      red.pawnArray[3].place(this.circles[43]);
      red.pawnArray[0].setOnBoard(true);
      red.pawnArray[1].setOnBoard(true);
      red.pawnArray[2].setOnBoard(true);
      red.pawnArray[3].setOnBoard(true);
      red.addOnBoard();
      red.addOnBoard();
      red.addOnBoard();
      red.addOnBoard();

      let blue = this.players[1];
      blue.pawnArray[0].place(this.circles[10]);
      blue.pawnArray[1].place(this.circles[9]);
      blue.pawnArray[2].place(this.circles[8]);
      blue.pawnArray[3].place(this.circles[7]);
      blue.pawnArray[0].setOnBoard(true);
      blue.pawnArray[1].setOnBoard(true);
      blue.pawnArray[2].setOnBoard(true);
      blue.pawnArray[3].setOnBoard(true);
      blue.addOnBoard();
      blue.addOnBoard();
      blue.addOnBoard();
      blue.addOnBoard();

      let yellow = this.players[2];
      yellow.pawnArray[0].place(this.circles[34]);
      yellow.pawnArray[1].place(this.circles[33]);
      yellow.pawnArray[2].place(this.circles[32]);
      yellow.pawnArray[3].place(this.circles[31]);
      yellow.pawnArray[0].setOnBoard(true);
      yellow.pawnArray[1].setOnBoard(true);
      yellow.pawnArray[2].setOnBoard(true);
      yellow.pawnArray[3].setOnBoard(true);
      yellow.addOnBoard();
      yellow.addOnBoard();
      yellow.addOnBoard();
      yellow.addOnBoard();

      let green = this.players[3];
      green.pawnArray[0].place(this.circles[22]);
      green.pawnArray[1].place(this.circles[21]);
      green.pawnArray[2].place(this.circles[20]);
      green.pawnArray[3].place(this.circles[19]);
      green.pawnArray[0].setOnBoard(true);
      green.pawnArray[1].setOnBoard(true);
      green.pawnArray[2].setOnBoard(true);
      green.pawnArray[3].setOnBoard(true);
      green.addOnBoard();
      green.addOnBoard();
      green.addOnBoard();
      green.addOnBoard();
    },
    pathBlocker() {
      let red = this.players[0];
      red.pawnArray[0].place(this.redPath[0]);
      red.pawnArray[1].place(this.redPath[1]);
      red.pawnArray[2].place(this.redPath[2]);
      red.pawnArray[3].place(this.circles[46]);
      red.pawnArray[0].setOnBoard(true);
      red.pawnArray[1].setOnBoard(true);
      red.pawnArray[2].setOnBoard(true);
      red.pawnArray[3].setOnBoard(true);
      red.addOnBoard();
      red.addOnBoard();
      red.addOnBoard();
      red.addOnBoard();

      let blue = this.players[1];
      blue.pawnArray[0].place(this.bluePath[0]);
      blue.pawnArray[1].place(this.bluePath[1]);
      blue.pawnArray[2].place(this.bluePath[2]);
      blue.pawnArray[3].place(this.circles[10]);
      blue.pawnArray[0].setOnBoard(true);
      blue.pawnArray[1].setOnBoard(true);
      blue.pawnArray[2].setOnBoard(true);
      blue.pawnArray[3].setOnBoard(true);
      blue.addOnBoard();
      blue.addOnBoard();
      blue.addOnBoard();
      blue.addOnBoard();

      let yellow = this.players[2];
      yellow.pawnArray[0].place(this.yellowPath[0]);
      yellow.pawnArray[1].place(this.yellowPath[1]);
      yellow.pawnArray[2].place(this.yellowPath[2]);
      yellow.pawnArray[3].place(this.circles[34]);
      yellow.pawnArray[0].setOnBoard(true);
      yellow.pawnArray[1].setOnBoard(true);
      yellow.pawnArray[2].setOnBoard(true);
      yellow.pawnArray[3].setOnBoard(true);
      yellow.addOnBoard();
      yellow.addOnBoard();
      yellow.addOnBoard();
      yellow.addOnBoard();

      let green = this.players[3];
      green.pawnArray[0].place(this.greenPath[0]);
      green.pawnArray[1].place(this.greenPath[1]);
      green.pawnArray[2].place(this.greenPath[2]);
      green.pawnArray[3].place(this.circles[22]);
      green.pawnArray[0].setOnBoard(true);
      green.pawnArray[1].setOnBoard(true);
      green.pawnArray[2].setOnBoard(true);
      green.pawnArray[3].setOnBoard(true);
      green.addOnBoard();
      green.addOnBoard();
      green.addOnBoard();
      green.addOnBoard();
    },
  },
  mounted() {
  },
  computed: {
    allPawnsSVG() {
      let svgMarkup = '';
      for (const player of this.players) {
        for (const pawn of player.pawnArray) {
          svgMarkup += pawn.renderSVG();
        }
      }
      return svgMarkup;
    }
  }
}
</script>

<style scoped>

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.board-position {
  border: 10px solid #42b983;
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.side-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-top: -30px;
}

.prompter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 20px;
}

.button-container {
  width: 400px;
  height: 100px;
}

.button-container button_dice {
  width: 75%;
  height: 75%;
}

.button_dev {
  white-space: nowrap;
  background-color: black;
  color: white;
  border: lightseagreen;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.gif {
  background-image: url('@/assets/orange1.gif');
  background-color: dimgrey;
  background-size: 180%;
}

h1 {
  margin-top: 50px;
  font-family: "Arial Black";
  color: black;
}

.button_dice {
  background-image: url('@/assets/BTBq.gif');
  background-size: 125%;
  background-position: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(10, 10, 10, 10);
  border: 5px solid #42b983;
  padding: 20px;
  width: 100px;
  height: 100px;
  margin-top: 20px;
}

.player-info {
  white-space: nowrap;
  background-color: black;
  color: white;
  width: 100%;
}


.dev-buttons-rows {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px;
}
</style>
