import {createRouter, createWebHistory} from 'vue-router'
import Login from "@/components/main/Login";
import Board from "@/components/Board";
import Chat from "@/components/Chat";
import Home from "@/components/main/Home";
import Intro from "@/components/AboutComponents/Intro";
import Lloyd from "@/components/AboutComponents/Lloyd";
import Max from "@/components/AboutComponents/Max.vue";
import Kevin from "@/components/AboutComponents/Kevin";
import Jorden from "@/components/AboutComponents/Jorden";
import ResetPassword from "@/components/main/ResetPassword.vue";
import spelregelsBegin from "@/components/spelregels/BasisRegels.vue";
import spelregelsBegin1 from "@/components/spelregels/Blokkades.vue";
import spelregelsBegin2 from "@/components/spelregels/PionnenVerliezen1.vue";
import spelregelsBegin3 from "@/components/spelregels/PionnenVerliezen2.vue";
import lobbyOverview from "@/components/LobbyOverview.vue";
import GameLobby from "@/components/GameLobby.vue";
import History from "@/components/main/History.vue";
import ScoreBoard from "@/components/main/ScoreBoard.vue";
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },{
    path: '/home',
    redirect: '/',
  }, {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: "/login",
    name: 'Login',
    component: Login
  }, {
    path: "/reset",
    name:"reset",
    component: ResetPassword
  }, {
    name: 'GameLobby',
    path: "/GameLobby",
    component: GameLobby
  }, {
    name: 'lobbyOverview',
    path: "/lobby",
    component: lobbyOverview,
  },
  {   path: "/game",
    name: "game",
    component: Board
  },{
    path: "/history",
    name: "history",
    component: History
  },{
    path: "/scoreboard",
    name: 'scoreboard',
    component: ScoreBoard
  }, {
    path: "/chat",
    name: 'Chat',
    component: Chat
  }, {
    path: "/intro",
    name: 'Intro',
    component: Intro
  }, {
    path: "/lloyd",
    name: 'Lloyd',
    component: Lloyd
  }, {
    path: "/kevin",
    name: 'Kevin',
    component: Kevin
  }, {
    path: "/jorden",
    name: 'Jorden',
    component: Jorden
  }, {
    path: "/max",
    name: 'Max',
    component: Max
  },{
    path: "/spelregelsBegin",
    component: spelregelsBegin
  },{
    path: "/spelregelsBegin1",
    component: spelregelsBegin1
  },{
    path: "/spelregelsBegin2",
    component: spelregelsBegin2
  },{
    path: "/spelregelsBegin3",
    component: spelregelsBegin3
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const nextRoute = sessionStorage.getItem("nextRoute");
  if (nextRoute) {
    sessionStorage.removeItem("nextRoute");
    next(nextRoute);
  } else {
    next();
  }
});

export default router
