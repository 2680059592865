<template>
  <div class="popup">
    <div class="popup-inner">
      <slot />
      <component :is="components[currentComponentIndex]" />
      <div class="spacing-bottom">
        <button class="spacing-right" @click="goToPrevious"><i class="bi-arrow-left"></i> vorige</button>
        <button @click="goToNext">volgende <i class="bi-arrow-right"></i></button>
      </div>
      <button class="popup-close" @click="TogglePopup">Sluit Spelregels</button>
    </div>
  </div>
</template>

<script>
import SpelregelsBegin from "@/components/spelregels/BasisRegels.vue";
import SpelregelsBegin1 from "@/components/spelregels/Blokkades.vue";
import SpelregelsBegin2 from "@/components/spelregels/PionnenVerliezen1.vue";
import SpelregelsBegin3 from "@/components/spelregels/PionnenVerliezen2.vue";

export default {
  components: {
    SpelregelsBegin,
    SpelregelsBegin1,
    SpelregelsBegin2,
    SpelregelsBegin3
  },
  props: ['TogglePopup'],
  data() {
    return {
      components: ['SpelregelsBegin', 'SpelregelsBegin1', 'SpelregelsBegin2', 'SpelregelsBegin3'],
      currentComponentIndex: 0
    }
  },
  methods: {
    goToNext() {
      if (this.currentComponentIndex < this.components.length - 1) {
        this.currentComponentIndex++;
      }
    },
    goToPrevious() {
      if (this.currentComponentIndex > 0) {
        this.currentComponentIndex--;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
button {
  border-radius: 20px;
  border: 1px solid #000000;
  background-color: #000000;
  margin-top: 5px;
  color: #f84e07;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform .1s ease-in;

  &:active {
    transform: scale(.9);
  }

  &:focus {
    outline: none;
  }
}

button.invert {
  background-color: transparent;
  border-color: #fff;
}

.spacing-bottom{
  margin-bottom: 10px;
}

.spacing-right{
  margin-right: 60px;
}

.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;

	.popup-inner {
    background: rgba(255, 255, 255, 0.65);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 12px;
    padding: 20px 35px 20px;
    margin-top: 50px;
    margin-bottom: 100px;
    display: inline-block;
	}
}
</style>
