<template>
  <div class="container">
    <h1>Scrummaster: Lloyd</h1>
    <br>
    <p>Ik ben Lloyd Larbi, ik ben de Scrummaster van team11. Mijn taak is om het team zodanig te leiden dat wij een goed product op de markt kunnen brengen.</p>
    <p>Ik ben 27 jaar en volg de studie Software Engineering en ik werk bij Alvant als Java Software Engineer.</p>
    <p>Ik ben tijdens dit project verantwoordelijk geweest voor het inloggen, registreren en de wachtwoordreset.</p>
    <p>Daarnaast heb ik me bezig gehouden met de back-end opzetten dat was natuurlijk nodig voor authenticatie, registratie etc.</p>
    <p>Ook heb ik mij beziggehouden met de front-end, waarbij ik heb nagedacht over de design, hoe we de SPA in elkaar wilden zetten en hoe we het wilden presenteren.</p>
  </div>
</template>

<script>
export default {
  name: "Lloyd"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: justify;
  position: marker;
  padding: 50px;
  color: rgb(255, 255, 255);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}

</style>
