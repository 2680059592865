<template>
  <div class="container">
    <h1>Developer: Max</h1>
    <br>
<p>Hallo, ik ben Max Turk, nieuw lid van dit  team sinds dit jaar.</p>
<p>ik ben 28 jaar oud en ik kom uit het mooie Leiden.</p>
<p>Op dit moment ben ik actief als ontwikkelaar bij de Provincie Zuid-Holland.</p>
<p>Tijdens ons project heb ik bezig gehouden met de backend en de CI/CD van dit project, waarbij ik me bezighield met cruciale taken zoals:</p>
<ul>
    <li>Het creëren van een api die resultaten ophaalt uit de backend en weergeeft op de frontend.</li>
    <li> <button @click="rollDice">Roll Dice</button>
    <div>Result: {{ result }}</div>
    <br>
  </li>
    <li>in elkaar zetten van  een soepele deployment en Continuous Integration/Continuous Deployment (CI/CD) van onze applicatie.</li>
    <li>Serverconfiguratie en -beheer om ervoor te zorgen dat alles soepel draait.</li>
    <li>De implementatie van een geautomatiseerde pipeline die onze applicatie moeiteloos uitrolt. Zodra er een nieuwe build is</li>
</ul>
<p>Mijn passie ligt in het uitzoeken hoe alles in elkaar zit en dit vervolgens toe te passen, en ik ben enthousiast om deel uit te maken van dit team!</p>

  </div>
</template>


<script>
export default {
    name: "Max",
    data() {
        return {
            result: null
        };
    },
    methods: {

    async rollDice() {
      try {
        const response = await fetch("https://api.mejn.maxturk.nl/api/game/rollDice");
        if(response.ok) {
          this.result = await response.json();
          console.log("Dice result:", this.result);
        } else {
          console.error("Failed to roll dice");
        }
      } catch(error) {
        console.error("Error:", error);
      }
    },
  }
};
</script>


<style scoped>
.container {
    z-index: 900;
  text-align: justify;
    position: relative;
    padding: 50px;
    color: rgb(255, 255, 255);
    font-family: "Arial", "Verdana", "Helvetica", serif;
    font-size: 20px;
}
</style>
