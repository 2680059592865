<template>
  <div class="container">
    <h1>Developer: Jorden</h1>
    <br>
    <p> Ik ben Jorden op den Kelder,  Ik ben 35 jaar oud en woon in Utrecht. </p>
    <p>Momenteel ben ik werkzaam als data analist en SAS developer naast mijn Software Engineering studie.</p>
    <p>Binnen dit project (MEJN) ben ik verantwoordelijk voor het boardgame en de functionaliteit ervan in de frontend.</p>
    <p>Tijdens ons project heb ik bezig gehouden met de backend en de CI/CD van dit project, waarbij ik me bezighield met cruciale taken zoals:</p>



    <p> Ik ben Jorden op den Kelder,  Ik ben 35 jaar oud en woon in Utrecht. </p>

    Ik ben 35 jaar oud en woon in Utrecht.
    Momenteel ben ik werkzaam als data analist en SAS developer naast mijn Software Engineering studie.

    Binnen dit project (MEJN) ben ik verantwoordelijk voor het boardgame en de functionaliteit ervan in de frontend.
  </div>
</template>

<script>
export default {
  name: "Jorden"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: justify;
  position: marker;
  padding: 50px;
  color: rgb(255, 255, 255);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}

</style>
