<template>
  <div class="container">
    <div class="row">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Chat'
}
</script>

<style scoped>

</style>
