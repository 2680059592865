import {Player} from "@/models/player";
import soundPawnStart from "@/assets/sound_edit_start.wav";

export class Logic{

  createPlayers(playerArray, numPlayers, names, print) {
    if (numPlayers < 1 || numPlayers > 4) {
      console.error('Number of players should be between 1 and 4');
      return;
    }

    const colors = ['red', 'blue', 'yellow', 'green'];
    const shuffledNames = names.sort(() => Math.random() - 0.5);

    for (let i = 0; i < numPlayers; i++) {
      const playerName = shuffledNames[i % numPlayers];
      const playerColor = colors[i];
      playerArray.push(new Player(playerName, playerColor, i + 1));
    }

    //fixme niet nodig?
    for (const player of playerArray) {
      if(player.playerColor === 'red'){
        player.setThrow(-1);
      }
    }

    if (playerArray != null && print) {
      for (const player of playerArray) {
        console.log(player.name, ' (', player.playerColor, ') joined the game!');
        console.log(player, '\n');
      }
      console.log('\n');
    }
  }
  execute(players, method){
    if(players!= null){
      for(const player of players){
        if(typeof player[method] === 'function')
          player[method]();
      }
    }
  }

  placePawnOnHome(bases, players,circles) {
    if (players) {
      for (const player of players) {
        const color = player.playerColor.toLowerCase();
        const base = bases[color];
        const startCircle = circles[(color === "red") ? 0 : (color === "blue") ? 12 : (color === "yellow") ? 36 : 24];
        if (base) {
          for (let i = 0; i < 4; i++) {
            const pawn = player.pawnArray[i];
            pawn.place(base[i]);
            pawn.homeBasePosX(base[i].x);
            pawn.homeBasePosY(base[i].y);
            pawn.setHomeCircle(base[i]);
            pawn.setStartPoint(startCircle);
            player.setPlayerStart(startCircle);
          }
        }
      }
    }
  }


}
