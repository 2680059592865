<template>
  <div class="container">
    <p> Een frisse nieuwe kijk op mens erger je niet, waarbij het spel moeilijker wordt d.m.v. nieuwe regels waardoor de speler(s) worden gedwongen om hun strategische denkwijze te trainen en aan te passen.</p>
    <p>Oftewel de introductie van mens erger je niet 2.0. Je leert kennis maken met onverwachte gebeurtenissen en daarop gelijk te anticiperen. </p>
    <p> Hier komt uitleg over de features.</p>
  </div>
</template>

<script>
export default {
  name: "Intro"
}
</script>

<style scoped>
.container{
  z-index: 900;
  text-align: justify;
  position: marker;
  padding: 50px;
  color: rgb(255, 255, 255);
  font-family: "Arial","Verdana","Helvetica",serif;
  font-size: 20px;
}


</style>
