<template>
  <p>Hello world</p>
</template>
<script>
export default {
 data(){
   return {
     name: 'history',
   }
 }
}
</script>

<style scoped>

</style>
