<template>
  <article>
    <div class ="container" :class="{'sign-up-active' : signUp}">
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-left">
            <h1>Welcome back</h1>
            <p>Do you already have an account?</p>
            <button class="invert" id="signIn" @click="signUp = !signUp">Login</button>
          </div>
          <div class="overlay-right">
            <h1>Register</h1>
            <p>No account yet?</p>
            <button class="invert" id="signUp" @click="signUp =!signUp">Register!</button>
          </div>
        </div>
      </div>
      <form class="sign-up" enctype='multipart/form-data' @submit.prevent="register">
        <h2 id="createTitle">Create account </h2>
        <div class="profilePic">
          <div class="imagePreviewWrapper" :style="{ 'background-image': `url(${previewImage})` }" @click="selectImage"></div>
          <input ref="fileInput" type="file" @input="pickFile">
        </div>
        <input type="text" placeholder="First Name" v-model="registerForm.firstName" required/>
        <input type="text" placeholder="Last name" v-model="registerForm.lastName" required/>
        <div v-if="emailError" class="error-message">{{ emailError }}</div>
        <input type="email" placeholder="Email" v-model="registerForm.email" required/>
        <div v-if="passwordError" class="error-message">{{ passwordError }}</div>
        <input type="password" placeholder="Password" v-model="registerForm.password" @input="validatePassword" @focusout="validatePassword" required/>
        <input type="password" placeholder="Confirm password" v-model="registerForm.passwordConfirm" required/>
        <button >Sign up</button>
      </form>
      <form class="sign-in" enctype='multipart/form-data' @submit.prevent=login>
        <h2>Sign in</h2>
        <div>Use your Credentials</div>
        <div v-if="loginError" class="error-message">{{ loginError }}</div>
        <input type="email" placeholder="Email" v-model="loginForm.email" required/>
        <input type="password" placeholder="Password" v-model="loginForm.password" required/>
        <div class="suggests">
          <div class="forgot">
            <span @click="showDiv = !showDiv">Forgot Password?</span>
          </div>
        </div>
        <button>Login</button>
      </form>
    </div>
    <div class="containerthings" v-show="showDiv">
      <form class="container-forgot" enctype='multipart/form-data' @submit.prevent="triggerMail(forgotForm.email)">
        <h2>Reset Password</h2>
        <div>Where Should we send the mail to?</div>
        <div v-if="forgotError" class="error-message">{{ forgotError }}</div>
        <input type="email" placeholder="Email/ Username" v-model="forgotForm.email"/>
        <button>Send mail</button>
      </form>
    </div>
  </article>
</template>

<script>

let baseUrl =  "https://mejn.maxturk.nl";

export default {
  data ()  {
    return {
      showDiv: false,
      loginError:"",
      emailError: "",
      passwordError: "",
      forgotError:"",
      email:'',
      loginForm:{
        email: '',
        password: '',
      },
      registerForm:{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm:'',
        image: '',
      },
      forgotForm:{
        email:'',
      },
      name: 'Login',
      previewImage: '',
      signUp: false
    }
  },
  watch: {
    signUp(newValue) {
      if (newValue) {
        this.showDiv = false;
      }
    },
  },
  methods: {
    async login(){
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.loginForm),
      };
      try {
        const response = await fetch(baseUrl+"/api/v1/auth/authenticate", fetchOptions);
        if (response.ok) {
          const data = await response.json();
          console.log(data.token);
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("loggedIn", "true");
          sessionStorage.setItem("nextRoute", "/lobby");
          location.reload();
        } else {
          this.validateLogin();
        }
      } catch(error) {
        console.error("There has been a problem with your fetch operation:", error);
      }
    },
    validateLogin() {
      this.loginError = "Email of wachtwoord niet gevonden, probeer het opnieuw.";
      },
    validatePassword() {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\W)(?=.{6,15}).*$/;
      if (!passwordRegex.test(this.registerForm.password)) {
        this.passwordError = 'Het wachtwoord moet minimaal 6 tot en met 15 tekens bevatten, 1 hoofdletter en 1 speciaal teken.';
        return false;
      } else if (!this.registerForm.password || this.registerForm.password === "") {
        this.passwordError = 'Het wachtwoord mag niet leeg zijn.';
        return false;
      } else if (this.registerForm.password !== this.registerForm.passwordConfirm) {
        this.passwordError = 'De wachtwoorden komen niet overeen.';
        return false;
      } else {
        this.passwordError = "";
        return true;
      }
    },
    async register() {
      if (!this.validatePassword()) {
        return;
      }
      const emailExists = await this.checkEmailExists(this.registerForm.email);
      if (emailExists) {
        console.error("Email already exists");
        this.emailError = 'Ingevoerde Email bestaat al.';
        return;
      }
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.registerForm),
      };
      try {
        const response = await fetch(baseUrl + "/api/v1/auth/register", fetchOptions);
        if (response.ok) {
          console.log(response);
          this.signUp = false;
          this.registerForm.email = '';
          this.registerForm.passwordConfirm = '';
          this.registerForm.lastName = '';
          this.registerForm.firstName = '';
          this.registerForm.password = '';
          this.registerForm.image = '';
          alert("Gegroet nieuwe speler,\n\nBevestigingsmail wordt verzonden, mocht het niet in je inbox zitten bekijk dan je spam!\n\nTot gauw! \n\nHet MEJN Team")
        } else {
          console.error("error");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async checkEmailExists(email) {
      try {
        const response = await fetch(baseUrl + "/api/v1/auth/check-email", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email }),
        });
        if (response.ok) {
          const data = await response.json();
          return data.exists;
        } else {
          console.error("Error checking email existence");
          return false;
        }
      } catch (error) {
        console.error("Error checking email existence:", error);
        return false;
      }
    },
    async triggerMail() {
      const emailExists = await this.checkEmailExists(this.forgotForm.email);
      if (!emailExists) {
        this.forgotError = 'Deze mail is niet bekend bij ons, probeer het opnieuw of registreer.';
        return;
      }
      try {
        const response = await fetch(baseUrl + '/api/v1/auth/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.forgotForm.email,
          }),
        });
        if (!response.ok) {
          console.log(`HTTP error! Status:' ${response.status}`);
        }
        console.log('Mail sent successfully');
        alert("Gegroet nieuwe speler,\n\nPassword reset mail verzonden, mocht het niet in je inbox zitten bekijk dan je spam!\n\nTot gauw! \n\nHet MEJN Team");
      } catch (error) {
        console.error('Error sending mail', error);
      }
    },
    selectImage () {
      this.$refs.fileInput.click()
    },
    pickFile (){
      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.error-message {
  color:red;
  margin: 1%;
}


.containerthings {
  position: relative;
  margin-top: 1%;
  left: 30%;
  width: 40%;
  height: 10%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0,0,0,.2),
  0 10px 10px  rgba(0,0,0,.2);
  background: linear-gradient(to bottom, #efefef, #ccc);

  .container-forgot{
    z-index: 200;
    position: relative;
    width: 60%;
    height: 10%;
    overflow: hidden;
    left: 20%;
  }
}

.container {
  position: relative;
  width: 50%;
  height: 700px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0,0,0,.2),
  0 10px 10px  rgba(0,0,0,.2);
  background: linear-gradient(to bottom, #efefef, #ccc);

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform .5s ease-in-out;
    z-index: 100;
  }

  .overlay {
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    background: linear-gradient(to bottom right, #000000, #f84e07);
    color: #fff;
    transform: translateX(0);
    transition: transform .5s ease-in-out;
  }

  @mixin overlays($property) {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 70px 40px;
    width: calc(50% - 80px);
    height: calc(100% - 140px);
    text-align: center;
    transform: translateX($property);
    transition: transform .5s ease-in-out;
  }

  .overlay-left {
    @include overlays(-20%);
    top: 10%;
    left: 2%;
  }

  .overlay-right {
    @include overlays(0);
    top: 10%;
    right: 2%;

  }
}

h2 {
  margin: 0;
}

#createTitle {
  margin-top: 50px;
}

p {
  margin: 20px 0 30px;
}

a {
  color: #222;
  text-decoration: none;
  margin: 15px 0;
  font-size: 1rem;
}

button {
  border-radius: 20px;
  border: 1px solid #000000;
  background-color: #000000;
  margin-top: 5px;
  color: #f84e07;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform .1s ease-in;

  &:active {
    transform: scale(.9);
  }

  &:focus {
    outline: none;
  }
}

button.invert {
  background-color: transparent;
  border-color: #fff;
}

form {
  position: absolute;
  top: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 90px 60px;
  width: calc(50% - 100px);
  height: calc(100% - 180px);
  text-align: center;
  background: transparent;
  transition: all .5s ease-in-out;

  div {
    font-size: 1rem;
  }

  input {
    background-color: #eee;
    border: none;
    padding: 20px 40px;
    margin: 6px 0;
    width: 120%;
    border-radius: 15px;
    border-bottom: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .4),
                      0 -1px 1px #fff,
                      0 1px 0 #fff;
    overflow: hidden;

    &:focus {
      outline: none;
      background-color: #fff;
    }
  }

  .suggests{
    width: 120%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
  }

  .suggests input{
    background: transparent;
    padding: 0;
    width: 10%;
    cursor: pointer;
  }

  .suggests .forgot{
    color: #f84e07;
    cursor: pointer;
  }

}

.sign-in {
  left: 4%;
  z-index: 1;
  opacity: 1;
}

.sign-up {
  left: 12%;
  z-index: 1;
  opacity: 0;
}

.forgot-password {
  left: 4%;
  z-index: 1;
  opacity: 1;
}

.profilePic {
  margin-left: 50px;
  margin-right: 100px;
  z-index: 1;
  cursor: pointer;
}

.imagePreviewWrapper {
  z-index: 1;
  border-radius: 20px;
  width: 150px;
  height: 150px;
  display: block;
  cursor: pointer;
  margin: 0 auto 5px 90px;
  background-position: center center;
}

.sign-up-active {
  .sign-in {
    transform: translateX(10%);
    opacity: 0;
    z-index: 5;
  }

  .sign-up {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show .5s;
  }

  .overlay-container {
    transform: translateX(-100%);
  }

  .overlay {
    transform: translateX(50%);
  }

  .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    transform: translateX(20%);
  }
}

@keyframes show {
  0% {
    opacity: 0;
    z-index: 1;
  }
  49% {
    opacity: 0;
    z-index: 1;
  }
  50% {
    opacity: 1;
    z-index: 10;
  }
}
</style>
