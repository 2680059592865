<template>
  <template>
    <div>
      <h2>Ludo High Scores</h2>
      <table class="table">
        <thead>
        <tr>
          <th>Rank</th>
          <th>Player Name</th>
          <th>Score</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(score, index) in scores" :key="score.id">
          <td>{{ index + 1 }}</td>
          <td>{{ score.playerName }}</td>
          <td>{{ score.points }}</td>
        </tr>
        </tbody>
      </table>

      <p>test</p>
    </div>
  </template>

</template>
<script>
export default {
  data() {
    return {
      scores: [
        { id: 1, playerName: 'Alice', points: 150 },
        { id: 2, playerName: 'Bob', points: 120 },
        { id: 3, playerName: 'Charlie', points: 100 },

      ]
    };
  }
}
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
</style>
