export class game {

  id
  title
  description
  image
  type

  /**
   *
   * @param pdId
   * @returns {game} instance of a newly create game
   */
  static createGame(pdId = 0) {
    const newGame = new game();
    newGame.id = pdId;
    newGame.type = newGame.randomGameType();
    newGame.title = this.title;
    newGame.description = newGame.gameDescription();
    newGame.image = newGame.randomImages();

    return newGame;
  }

  static Images = [
    require('../../src/assets/starworld.gif'),
    require('../../src/assets/eddieApprove.gif'),
    require('../../src/assets/JokerGirl.gif'),
    require('../../src/assets/joker.gif'),
    require('../../src/assets/MensErgerJeNietLogo.jpeg'),
    require('../../src/assets/hell4.gif'),
    require('../../src/assets/pinhead2.gif'),
    require('../../src/assets/helpT.png'),
    require('../../src/assets/212822.gif'),
  ]

  static Type = {
    PLAYER_VS_PLAYER: "Player vs Player",
    PLAYER_VS_COMPUTER: "Player vs Computer",
    TUTORIAL: "Tutorial"
  }


  /**
   * Provide the description of a game instance
   */
  gameDescription() {
    const descList = [
      "A simple game of four men MEJN where its free for all",
      "A game against a so called AI to test your MEJN skills against a bot",
      "The basics of MEJN with walk through assistants"
    ];
    let pickDescription = Math.floor(Math.random() * descList.length);
    return descList[pickDescription];
  }

  /**
   * Provide a random list of images
   */
  randomImages() {
    const imageList = game.Images;
    let pickImage = Math.floor(Math.random() * imageList.length)

    return imageList[pickImage];
  }

  /**
   * Provide a random cabin type
   */
  randomGameType() {
    const gameType = Math.random() * 3;
    if (gameType < 1) {
      return game.Type.PLAYER_VS_PLAYER
    } else if (gameType < 2) {
      return game.Type.PLAYER_VS_COMPUTER
    } else {
      return game.Type.TUTORIAL
    }
  }

}
