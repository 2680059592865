<template>
  <div class="container">
    <div class="row">
      <h1>Game Overview</h1>
      <form class="create-game" action="">
        <div class="game-name">
          <input v-model="games.title" class="form-control-sm mt-2" placeholder="create a game" autocomplete="off"/>
        </div>
        <div class="game-desc">
        <textarea class="form-control-sm mt-2"
                  cols="25"
                  rows="3"
                  v-model="games.description"
                  placeholder="Game description"></textarea>
        </div>
        <button class="btn btn-sm bg-gradient btn-success mb-2" @click="onNewGame">Create Game</button>
      </form>

      <div class="col-md-6 col-xl-3 mt-5"
           v-for="game in games"
           :key="game.id" style="width: 18rem;">
        <div class="card"
             @click="selectGame(game)"
             :class="{ 'selection': game === selectedGame }">
          <img :src="game.image" class="card-img-top image" :alt="game.image">
          <div class="card-body">
            <h5 class="card-title">{{ game.title }}</h5>
            <h6 class="badge text-bg-secondary bg-gradient">{{ game.type }}</h6>
            <p class="card-text">{{ game.description }}</p>
            <button @click="openGameLobby()" class="btn btn-sm btn-success bg-gradient">
              <!--              FIXME remove hard code with dynamic code for the badge, think of a counter method-->
              {{ game.id }} | Join game <span class="badge text-bg-danger"> 0 / {{ FULL_LOBBY }} </span></button>
          </div>
          <!--          <GameLobby v-if="onShowGameLobby()"></GameLobby>-->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {game} from "@/models/game";
import GameLobby from "@/components/GameLobby.vue";


export default {
  name: 'LobbyOverview',
  components: {
    // GameLobby
  },
  /*  sockets: {
      connect: function () {
        console.log('socket connected')
      },
      customEmit: function (data) {
        console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
      }
    },*/
  //FIXME bug where new instance is pushed but rerenders page
  created() {
    this.lastId = 10000;
    this.onListGames();
  },
  data() {
    return {
      games: [],
      images: game.Images,
      players: [],
      description: null,
      selectedGame: null,
      FULL_LOBBY: 4,
      connection: null,
      joinedPlayers: null,
      gameId: 0,
      lastId: 0,
      showGameLobby: false,
    }
  },
  computed: {},
  methods: {
    clickButton: function (data) {
      // $socket is socket.io-client instance
      this.$socket.emit('emit_method', data)
    },
    /**
     * selects a game
     * @param game
     */
    selectGame(game) {
      if (this.selectedGame === null) {
        console.log(this.selectedGame = game)
      } else if (this.selectedGame.id === game.id) {
        console.log(this.selectedGame = null)
      } else {
        console.log(this.selectedGame = game)
      }
    },
    /**
     * creates new game on click event
     */
    onNewGame() {
      this.games.push(
        game.createGame(this.nextId()));
    },

    nextId() {
      for (let i = 0; i <= this.games.length; i++) {
        this.gameId = this.lastId + (i);
      }
      return this.gameId;
    },

    onListGames() {
      const MAX_ACTIVE_GAMES = 6;
      for (let i = 0; i < MAX_ACTIVE_GAMES; i++) {
        this.games.push(
          game.createGame(this.nextId()));
      }
    },

    openGameLobby() {
      this.$router.push({name: 'GameLobby', params: {game: this.selectedGame.id}})
    },

    onShowGameLobby() {
      this.showGameLobby = true
      return this.showGameLobby;
    }
  }
}

</script>

<style scoped>
.selection {
  background-color: rgb(138, 206, 243);
  border: black;
}


</style>
