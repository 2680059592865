import {Circle} from "@/models/circle";

export class BoardSVG {

  boardGameSvg
  (
    tagId,
    mainPath,
    redPath,
    redBase,
    bluePath,
    blueBase,
    greenPath,
    greenBase,
    yellowPath,
    yellowBase,
  ){

    const printer= true;
    const printerXY = false;
    const printHome= false;
    const printWinPath = false;

    //Game Colors
    const color1 = "white"
    const colorStroke = "black"
    const redbase = "red"
    const bluebase = "blue"
    const greenbase = "green"
    const yellowbase = "yellow"

    //Code Names
    const main = "mainpath-"
    const bp = "bluepath-"
    const rp = "redpath-"
    const gp = "greenpath-"
    const yp = "yellowpath-"
    const rh = "redhome-"
    const bh = "bluehome-"
    const gh = "greenhome-"
    const yh = "yellowhome-"

    const multiplier = 80;
    const radius = 35;
    const svgns = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgns, "svg");
    svg.setAttribute("width", "1200");
    svg.setAttribute("height", "1200");

    //FIXME de For loops herschrijven zodat alles met 1 loop kan

    function rowX(start,end,path,x,y,multiplier,array){
      for (let i = start; i < end; i++) {
        let circle = new Circle(
          i,
          path,
          (x + (multiplier * (i - start))),
          y,
          radius,
          color1,
          colorStroke);
        array.push(circle)
      }
    }

    function rowY(start,end,path,x,y,multiplier,array){
      for (let i = start; i < end; i++) {
        let circle = new Circle(
          i,
          path,
          x,
          (y - (multiplier * (i - start))),
          radius,
          color1,
          colorStroke);
        array.push(circle)
      }
    }

    function rowXPos(start,end,path,x,y,multiplier,array){
      for (let i = start; i <= end; i++) {
        let circle = new Circle(
          i,
          path,
          (x + (multiplier * (i - start))),
          y,
          radius,
          color1,
          colorStroke);
        array.push(circle)
      }
    }

    function rowYPos(start,end,path,x,y,multiplier,array){
      for (let i = start; i <= end; i++) {
        let circle = new Circle(
          i,
          path,
          x,
          (y + (multiplier * (i - start))),
          radius,
          color1,
          colorStroke);
        array.push(circle)
      }
    }

    function rowXN(start,end,path,x,y,multiplier,array){
      for (let i = start; i <= end; i++) {
        let circle = new Circle(
          i,
          path,
          (x - (multiplier * (i - start))),
          y,
          radius,
          color1,
          colorStroke);
        array.push(circle)
      }
    }

    function rowYN(start,end,path,x,y,multiplier,array){
      for (let i = start; i <= end; i++) {
        let circle = new Circle(
          i,
          path,
          x,
          (y - (multiplier * (i - start))),
          radius,
          color1,
          colorStroke);
        array.push(circle)
      }
    }

    function single(index,path,x,y,array){
      let circle = new Circle(
        index,
        path,
        x,
        y,
        radius,
        "purple",
        colorStroke)
      array.push(circle)
    }

    rowX(0,6,main,115,519.3,multiplier,mainPath)
    rowY(6,11,main,515,440,multiplier,mainPath)
    single(11,main,595,120,mainPath)

    rowYPos(12,16,main,675,120,multiplier,mainPath)
    rowXPos(17,22,main,675,520,multiplier,mainPath)
    single(23,main,1075,600,mainPath)

    rowXN(24,29,main,1075,680,multiplier,mainPath)
    rowYPos(30,34,main,675,760,multiplier,mainPath)
    single(35,main,595,1080,mainPath)

    rowYN(36,40,main,515,1080,multiplier,mainPath)
    rowXN(41,46,main,515,680,multiplier,mainPath)
    single(47,main,115,600,mainPath)

    let greyCircleRed = new Circle(0, rp, 195, 600, 35, "grey", colorStroke)
    redPath.push(greyCircleRed)
    for (let i = 1; i <= 4; i++) {
      let circle = new Circle(
        i,
        rp,
        ((195) + (multiplier * i)),
        (600),
        radius,
        redbase,
        colorStroke);
      redPath.push(circle)
    }

    let greyCircleBlue = new Circle(0, bp, 595, 200, 35, "grey", colorStroke)
    bluePath.push(greyCircleBlue)
    for (let i = 1; i <= 4; i++) {
      let circle = new Circle(
        i,
        bp,
        (595),
        ((200) + ((multiplier) * (i))),
        radius,
        bluebase,
        colorStroke);
      bluePath.push(circle)
    }

    let greyCircleGreen = new Circle(0, gp, 995, 600, 35,  "grey", colorStroke)
    greenPath.push(greyCircleGreen)
    for (let i = 1; i <= 4; i++) {
      let circle = new Circle(
        i,
        gp,
        ((995) - ((multiplier) * (i))),
        (600),
        radius,
        greenbase,
        colorStroke);
      greenPath.push(circle)
    }

    let greyCircleYellow = new Circle(0, yp, 595, 1000, 35, "grey", colorStroke)
    yellowPath.push(greyCircleYellow)
    for (let i = 1; i <= 4; i++) {
      let circle = new Circle(
        i,
        yp,
        (595),
        ((1000) - ((multiplier) * (i))),
        radius,
        yellowbase,
        colorStroke);
      yellowPath.push(circle)
    }

    //Home Base:
    //RED
    let diffXHome2 = 900;
    let diffYHome2 = 100;
    redBase.push(new Circle(0, rh, 1045 - diffXHome2, 290 - diffYHome2, 35, redbase, colorStroke))
    redBase.push(new Circle(1, rh, 1110 - diffXHome2, 330 - diffYHome2, 35, redbase, colorStroke))
    redBase.push(new Circle(3, rh, 1255 - diffXHome2, 290 - diffYHome2, 35, redbase, colorStroke))
    redBase.push(new Circle(2, rh, 1190 - diffXHome2, 330 - diffYHome2, 35, redbase, colorStroke))
    //BLUE
    let diffXHome1 = 210;
    let diffYHome1 = 100;
    blueBase.push(new Circle(0, bh, 1045 - diffXHome1, 290 - diffYHome1, 35, bluebase, colorStroke))
    blueBase.push(new Circle(1, bh, 1110 - diffXHome1, 330 - diffYHome1, 35, bluebase, colorStroke))
    blueBase.push(new Circle(3, bh, 1255 - diffXHome1, 290 - diffYHome1, 35, bluebase, colorStroke))
    blueBase.push(new Circle(2, bh, 1190 - diffXHome1, 330 - diffYHome1, 35, bluebase, colorStroke))
    //YELLOW
    let diffXHome3 = 900;
    let diffYHome3 = -750;
    yellowBase.push(new Circle(0, yh, 1045 - diffXHome3, 290 - diffYHome3, 35, yellowbase, colorStroke))
    yellowBase.push(new Circle(1, yh, 1110 - diffXHome3, 250 - diffYHome3, 35,  yellowbase, colorStroke))
    yellowBase.push(new Circle(2, yh, 1190 - diffXHome3, 250 - diffYHome3, 35,  yellowbase, colorStroke))
    yellowBase.push(new Circle(3, yh, 1255 - diffXHome3, 290 - diffYHome3, 35, yellowbase, colorStroke))
    //GREEN
    let diffXHome4 = 210;
    let diffYHome4 = -750;
    greenBase.push(new Circle(0, gh, 1045 - diffXHome4, 290 - diffYHome4, 35, greenbase, colorStroke))
    greenBase.push(new Circle(1, gh, 1110 - diffXHome4, 250 - diffYHome4, 35, greenbase, colorStroke))
    greenBase.push(new Circle(2, gh, 1190 - diffXHome4, 250 - diffYHome4, 35, greenbase, colorStroke))
    greenBase.push(new Circle(3, gh, 1255 - diffXHome4, 290 - diffYHome4, 35, greenbase, colorStroke))


    if(printerXY){
      console.log("\nPrinterXY\n")
      for (const c of mainPath) {
        console.log("\nID: "+c.circleID+"\n"+"\nID: "+c.x+"\n"+"\nID: "+c.y+"\n");
      }
    }

    if(printer){
      console.log("\nCircle information SVG: "+print+"\nMain Path Array\n")
      for (const c of mainPath) {
        console.log(c);
      }
    }

    if(printHome){
      console.log("\nred\n")
      for(const c of redBase){
        console.log(c);
      }
      console.log("\nblue\n")
      for(const c of blueBase){
        console.log(c);
      }
      console.log("\ngreen\n")
      for(const c of greenBase){
        console.log(c);
      }
      console.log("\nyellow\n")
      for(const c of yellowBase){
        console.log(c);
      }
    }

    if(printWinPath){
      console.log("\nred winning path\n")
      for(const c of redPath){
        console.log(c);
      }
      console.log("\nblue winning path\n")
      for(const c of bluePath){
        console.log(c);
      }
      console.log("\ngreen winning path\n")
      for(const c of greenPath){
        console.log(c);
      }
      console.log("\nyellow winning path\n")
      for(const c of yellowPath){
        console.log(c);
      }
    }

  }

}








