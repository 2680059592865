export class Dice{

  roll(){
    return Math.floor(Math.random() * 6) + 1;
  }

  rollTime() {
    const now = new Date();
    const time = now.toISOString().substring(11, 19);
    const num = Math.floor(Math.random() * 9000) + 1000;
    const roll = Math.floor(Math.random() * 6) + 1;
    const stamp = "Roll: " + roll + " " + "Stamp: " + time + " " + num;
    console.log(stamp);
    return roll;
  }




}


